import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import frontend from './frontend'
import adminPanel from './admin'
import studentPanel from './student'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
        offset: {
          x: 0,
          y: 80,
        },
      }
    }
    return {
      behavior: 'smooth',
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...frontend,
    ...adminPanel,
    ...studentPanel,
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        resource: 'Auth',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        resource: 'Auth',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/not-authorized',
      name: 'misc-not-authorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/uploader/*',
      component: () => import('@/views/error/Blank.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.beforeEach((to, from, next) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  const userData = getUserData()
  const ability = userData ? userData.ability : []
  let found = false
  const BreakException = {}

  const isLoggedIn = isUserLoggedIn()
  // Redirect to login if not logged in
  if (!isLoggedIn && to.path !== '/login' && (to.meta.resource !== '*' && to.meta.resource !== 'Auth')) return next({ name: 'login' })

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser(userData ? userData.roles[0] : null))
  }

  if (!hasQueryParams(to) && hasQueryParams(from)) {
    return next({
      name: to.name,
      query: from.query,
    })
  }

  if (to.meta.resource && (to.meta.resource !== '*' && to.meta.resource !== 'Auth')) {
    try {
      Object.keys(ability)
        .forEach(key => {
          if (ability[key].subject === to.meta.resource) {
            found = true
            throw BreakException
          }
        })
    } catch (e) {
      console.log(e)
    }
  } else {
    found = true
  }
  if (!found) {
    return next({ name: 'misc-not-authorized' })
  }
  return next()
})

export default router

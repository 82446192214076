import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
Vue.mixin({
  methods: {
    SHBIF() {
      alert(11)
    },
  },
})
Vue.component(FeatherIcon.name, FeatherIcon)

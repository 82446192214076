const domain = 'https://edu.startupchoikhona.tj/backend'
export default {
  // Endpoints
  loginEndpoint: `${domain}/api/login`,
  registerEndpoint: `${domain}/api/register`,
  refreshEndpoint: `${domain}/api/refresh-token`,
  logoutEndpoint: `${domain}/api/logout`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}

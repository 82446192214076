export default [
  {
    path: '/student/courses',
    name: 'student-courses',
    component: () => import('@/views/student/courses/list/ECommerceShop.vue'),
    meta: {
      pageTitle: 'Курсы',
      contentClass: 'course-application',
      breadcrumb: [
        {
          text: 'Курсы',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/webinars',
    name: 'student-webinars',
    component: () => import('@/views/student/webinars/list/ECommerceShop.vue'),
    meta: {
      pageTitle: 'Вебинары',
      breadcrumb: [
        {
          text: 'Вебинары',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/certificates',
    name: 'student-certificates',
    component: () => import('@/views/student/certificates/Index.vue'),
    meta: {
      pageTitle: 'Сертификат студентов',
      breadcrumb: [
        {
          text: 'Сертификат студентов',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/calendar',
    name: 'student-calendar',
    component: () => import('@/views/student/calendar/Calendar.vue'),
    meta: {
      pageTitle: 'Календарь',
      breadcrumb: [
        {
          text: 'Календарь',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/library',
    name: 'student-library',
    component: () => import('@/views/student/library/Index.vue'),
    meta: {
      pageTitle: 'Список файлов',
      breadcrumb: [
        {
          text: 'Список файлов',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/forum',
    name: 'student-forum',
    component: () => import('@/views/student/forum/chatters-list/ChattersList.vue'),
    meta: {
      pageTitle: 'Форум',
      breadcrumb: [
        {
          text: 'Форум',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/forum/post/:id',
    name: 'student-forum-post',
    component: () => import('@/views/student/forum/chatters-list/ChatterPostsList.vue'),
    meta: {
      pageTitle: 'Форум',
      breadcrumb: [
        {
          text: 'Форум',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/chat',
    name: 'student-chat',
    component: () => import('@/views/student/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
]

export default [
  {
    path: '/home',
    name: 'home-page-dashboard',
    component: () => import('@/views/dashboard/home/Index.vue'),
    meta: {
      pageTitle: 'Главная',
      breadcrumb: [
        {
          text: 'Главная',
          active: true,
        },
      ],
    },
  },
  {
    path: '/home',
    name: 'home-page-dashboard',
    component: () => import('@/views/dashboard/home/Index.vue'),
    meta: {
      pageTitle: 'Главная',
      breadcrumb: [
        {
          text: 'Главная',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users',
    name: 'users-page-dashboard',
    component: () => import('@/views/dashboard/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Пользователи',
      breadcrumb: [
        {
          text: 'Пользователи',
          active: true,
        },
      ],
    },
  },
  {
    path: '/profile',
    name: 'profile-page-dashboard',
    component: () => import('@/views/dashboard/profile/Index.vue'),
    meta: {
      pageTitle: 'Профиль',
      breadcrumb: [
        {
          text: 'Профиль',
          active: true,
        },
      ],
    },
  },
  {
    path: '/courses',
    name: 'courses-page-dashboard',
    component: () => import('@/views/dashboard/courses/courses-list/CoursesList.vue'),
    meta: {
      pageTitle: 'Курсы',
      breadcrumb: [
        {
          text: 'Курсы',
          active: true,
        },
      ],
    },
  },
  {
    path: '/course/create',
    name: 'course-create-page-dashboard',
    component: () => import('@/views/dashboard/courses/courses-add/CourseListAddNew.vue'),
    meta: {
      pageTitle: 'Добавить курс',
      breadcrumb: [
        {
          text: 'Курсы',
          to: '/courses',
        },
        {
          text: 'Добавить курс',
          active: true,
        },
      ],
    },
  },
  {
    path: '/course/update/:id',
    name: 'course-update-page-dashboard',
    component: () => import('@/views/dashboard/courses/courses-edit/CoursesEdit.vue'),
    meta: {
      pageTitle: 'Изменение курс',
      breadcrumb: [
        {
          text: 'Курсы',
          to: '/courses',
        },
        {
          text: 'Изменение курс',
          active: true,
        },
      ],
    },
  },
  {
    path: '/webinars',
    name: 'webinars-page-dashboard',
    component: () => import('@/views/dashboard/webinars/webinars-list/WebinarsList.vue'),
    meta: {
      pageTitle: 'Вебинары',
      breadcrumb: [
        {
          text: 'Вебинары',
          active: true,
        },
      ],
    },
  },
  {
    path: '/saved-webinars',
    name: 'saved-webinars-page-dashboard',
    component: () => import('@/views/dashboard/webinars/saved-list/SavedsList.vue'),
    meta: {
      pageTitle: 'Сохраненные вебинары',
      breadcrumb: [
        {
          text: 'Сохраненные вебинары',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setting-bbb',
    name: 'setting-bbb-page-dashboard',
    component: () => import('@/views/dashboard/webinars/Setting.vue'),
    meta: {
      pageTitle: 'Настройка BigBlueButton',
      breadcrumb: [
        {
          text: 'Настройка BigBlueButton',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-test',
    name: 'report-test-page-dashboard',
    component: () => import('@/views/dashboard/report/report-test/TestReportsList.vue'),
    meta: {
      pageTitle: 'Отчет по тестирование',
      breadcrumb: [
        {
          text: 'Отчет по тестирование',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-test/:id/view',
    name: 'report-test-view-page-dashboard',
    component: () => import('@/views/dashboard/report/report-test/TestReportView.vue'),
    meta: {
      pageTitle: 'Отчет по тестирование',
      breadcrumb: [
        {
          text: 'Отчет по тестирование',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-feedback',
    name: 'report-feedback-page-dashboard',
    component: () => import('@/views/dashboard/report/report-feedback/FeedbackReportsList.vue'),
    meta: {
      pageTitle: 'Отчет по оценка курса',
      breadcrumb: [
        {
          text: 'Отчет по оценка курса',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-feedback/:id/view/:userID',
    name: 'report-feedback-view-page-dashboard',
    component: () => import('@/views/dashboard/report/report-feedback/FeedbackReportView.vue'),
    meta: {
      pageTitle: 'Отчет по оценка курса',
      breadcrumb: [
        {
          text: 'Отчет по оценка курса',
          active: true,
        },
      ],
    },
  },
  {
    path: '/chat',
    name: 'chat-dashboard',
    component: () => import('@/views/dashboard/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/forum',
    name: 'forum-page-dashboard',
    component: () => import('@/views/dashboard/forum/chatters-list/ChattersList.vue'),
    meta: {
      pageTitle: 'Форум',
      breadcrumb: [
        {
          text: 'Форум',
          active: true,
        },
      ],
    },
  },
  {
    path: '/forum/post/:id',
    name: 'forum-post-page-dashboard',
    component: () => import('@/views/dashboard/forum/chatters-list/ChatterPostsList.vue'),
    meta: {
      pageTitle: 'Форум',
      breadcrumb: [
        {
          text: 'Форум',
          active: true,
        },
      ],
    },
  },
  {
    path: '/calendar',
    name: 'calendar-page-dashboard',
    component: () => import('@/views/dashboard/calendar/Calendar.vue'),
    meta: {
      pageTitle: 'Календарь',
      breadcrumb: [
        {
          text: 'Календарь',
          active: true,
        },
      ],
    },
  },
  {
    path: '/manual/type-work',
    name: 'manual-type-work-page-dashboard',
    component: () => import('@/views/dashboard/manual/typeWorks/type-work-list/typeWorksList.vue'),
    meta: {
      pageTitle: 'Сферы деятельности',
      breadcrumb: [
        {
          text: 'Сферы деятельности',
          active: true,
        },
      ],
    },
  },
  {
    path: '/library/category',
    name: 'category-book-page-dashboard',
    component: () => import('@/views/dashboard/library/categories/category-list/CategoriesList.vue'),
    meta: {
      pageTitle: 'Справочник категорий',
      breadcrumb: [
        {
          text: 'Справочник категорий',
          active: true,
        },
      ],
    },
  },
  {
    path: '/library/books',
    name: 'books-page-dashboard',
    component: () => import('@/views/dashboard/library/books/books-list/BookList.vue'),
    meta: {
      pageTitle: 'Список файлов',
      breadcrumb: [
        {
          text: 'Список файлов',
          active: true,
        },
      ],
    },
  },
  {
    path: '/students-certificate',
    name: 'student-certificates-page-dashboard',
    component: () => import('@/views/dashboard/certificates/certificate-students/CertificatesList.vue'),
    meta: {
      pageTitle: 'Сертификат студентов',
      breadcrumb: [
        {
          text: 'Сертификат студентов',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template-certificates',
    name: 'template-certificate-page-dashboard',
    component: () => import('@/views/dashboard/certificates/certificate-templates/CertificatesList.vue'),
    meta: {
      pageTitle: 'Шаблон сертификатов',
      breadcrumb: [
        {
          text: 'Шаблон сертификатов',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template-certificates/update/:id',
    name: 'template-certificate-update-page-dashboard',
    component: () => import('@/views/dashboard/certificates/certificate-templates/CertificatesEdit.vue'),
    meta: {
      pageTitle: 'Редактирование шаблон',
      breadcrumb: [
        {
          text: 'Шаблон сертификатов',
          to: '/template-certificates',
        },
        {
          text: 'Редактирование шаблон',
          active: true,
        },
      ],
    },
  },
  {
    path: '/landing/menu',
    name: 'menu-landing-page-dashboard',
    component: () => import('@/views/dashboard/frontend-manages/menu/menu-list/MenusList.vue'),
    meta: {
      pageTitle: 'Меню',
      breadcrumb: [
        {
          text: 'Управление фронтендам',
          active: false,
        },
        {
          text: 'Меню',
          active: true,
        },
      ],
    },
  },
  {
    path: '/landing/slider',
    name: 'slider-landing-page-dashboard',
    component: () => import('@/views/dashboard/frontend-manages/slide/slides-list/SlideList.vue'),
    meta: {
      pageTitle: 'Слайдеры',
      breadcrumb: [
        {
          text: 'Управление фронтендам',
          active: false,
        },
        {
          text: 'Слайдеры',
          active: true,
        },
      ],
    },
  },
  {
    path: '/landing/about-project',
    name: 'about-project-landing-page-dashboard',
    component: () => import('@/views/dashboard/frontend-manages/about-project/Index.vue'),
    meta: {
      pageTitle: 'О проекте',
      breadcrumb: [
        {
          text: 'Управление фронтендам',
          active: false,
        },
        {
          text: 'О проекте',
          active: true,
        },
      ],
    },
  },
  {
    path: '/landing/created-for',
    name: 'created-for-landing-page-dashboard',
    component: () => import('@/views/dashboard/frontend-manages/created-for/created-for-list/CreatedsList.vue'),
    meta: {
      pageTitle: 'Для кого создан проект',
      breadcrumb: [
        {
          text: 'Управление фронтендам',
          active: false,
        },
        {
          text: 'Для кого создан проект',
          active: true,
        },
      ],
    },
  },
  {
    path: '/landing/services',
    name: 'service-landing-page-dashboard',
    component: () => import('@/views/dashboard/frontend-manages/service/service-list/ServicesList.vue'),
    meta: {
      pageTitle: 'Услуги',
      breadcrumb: [
        {
          text: 'Управление фронтендам',
          active: false,
        },
        {
          text: 'Услуги',
          active: true,
        },
      ],
    },
  },
  {
    path: '/landing/our-mentors',
    name: 'our-mentors-landing-page-dashboard',
    component: () => import('@/views/dashboard/frontend-manages/our-mentors/mentors-list/UsersList.vue'),
    meta: {
      pageTitle: 'Наши менторы',
      breadcrumb: [
        {
          text: 'Управление фронтендам',
          active: false,
        },
        {
          text: 'Наши менторы',
          active: true,
        },
      ],
    },
  },
  {
    path: '/landing/testimonials',
    name: 'testimonials-landing-page-dashboard',
    component: () => import('@/views/dashboard/frontend-manages/testimonial/testimonials-list/TestimonialsList.vue'),
    meta: {
      pageTitle: 'Отзывы',
      breadcrumb: [
        {
          text: 'Управление фронтендам',
          active: false,
        },
        {
          text: 'Отзывы',
          active: true,
        },
      ],
    },
  },
  {
    path: '/landing/blogs',
    name: 'blogs-landing-page-dashboard',
    component: () => import('@/views/dashboard/frontend-manages/blog/blogs-list/BlogsList.vue'),
    meta: {
      pageTitle: 'Новости',
      breadcrumb: [
        {
          text: 'Управление фронтендам',
          active: false,
        },
        {
          text: 'Новости',
          active: true,
        },
      ],
    },
  },
  {
    path: '/landing/footer',
    name: 'footer-landing-page-dashboard',
    component: () => import('@/views/dashboard/frontend-manages/footer/footer-list/FootersList.vue'),
    meta: {
      pageTitle: 'Футер',
      breadcrumb: [
        {
          text: 'Управление фронтендам',
          active: false,
        },
        {
          text: 'Футер',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setting/roles-permission',
    name: 'setting-roles-permission',
    component: () => import('@/views/dashboard/setting/roles-permission/roles/role-list/RolesList.vue'),
    meta: {
      pageTitle: 'Роли и привилегии',
      breadcrumb: [
        {
          text: 'Настройка',
          active: false,
        },
        {
          text: 'Роли и привилегии',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setting/roles-permission/:id/permission',
    name: 'setting-permissions',
    component: () => import('@/views/dashboard/setting/roles-permission/permission/Index.vue'),
    meta: {
      pageTitle: 'Привилегии',
      breadcrumb: [
        {
          text: 'Настройка',
          active: false,
        },
        {
          text: 'Роли и привилегии',
          to: '/setting/roles-permission',
        },
        {
          text: 'Привилегии',
          active: true,
        },
      ],
    },
  },
]

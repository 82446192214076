export default [
  {
    path: '/',
    name: 'home-landing',
    component: () => import('@/views/landing/Home.vue'),
    meta: {
      layout: 'frontend-horizontal',
      resource: '*',
    },
  },
  {
    path: '/webinar-list',
    name: 'webinar-list',
    component: () => import('@/views/landing/webinars/ECommerceShop.vue'),
    meta: {
      layout: 'frontend-horizontal',
      resource: '*',
    },
  },
  {
    path: '/webinar/:id/connect',
    name: 'webinar-connect',
    component: () => import('@/views/landing/Webinar.vue'),
    meta: {
      layout: 'frontend-horizontal',
      resource: '*',
    },
  },
  {
    path: '/news-details/:id',
    name: 'news-details',
    component: () => import('@/views/landing/newsView/NewsDetails.vue'),
    meta: {
      layout: 'frontend-horizontal',
      resource: '*',
    },
  },
  {
    path: '/course-details/:id',
    name: 'course-details',
    component: () => import('@/views/landing/courses/CoursesDetails.vue'),
    meta: {
      layout: 'frontend-horizontal',
      resource: '*',
    },
  },
  {
    path: '/course-details/:id/fullscreen/:m?/:h?/:l?',
    name: 'course-details-fullscreen',
    component: () => import('@/views/landing/courses/CoursesDetailsFullScreen.vue'),
    meta: {
      layout: 'full',
      resource: '*',
    },
  },
]
